body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

#safari-notice {
  display: block;
  margin: auto;
  background: rgba(255,255,255,0.8);
  border-radius: 10px;
  width: 50%;
  font-size: 2rem;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
  color: #333;
  text-align: center;
  font-weight: bold;
}

/* better line behaviour */
sup {
  vertical-align: top;
  top:-0.3rem;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  background: linear-gradient(180deg, rgba(176,176,224,1) 0%, rgba(193,206,231,1) 28%, rgba(255,255,255,1) 100%);
}

body {
  font-family: "Open Sans", sans-serif;
  color: #111;
}

.bold {
  font-weight: bold;
}

.references li {
  margin-top: 10px;
  color: #999;
}

table {
  border-spacing: 0px;
}

table tr {
  padding: 0;
  box-sizing: border-box;
}

table tr td {
  padding: 5px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
}

table td p {
  padding: 0px;
  margin: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}

.content-area {
  border-radius: 4px 4px 0 0;
  position: relative;
  background: white;
  margin: auto;
  margin-top: 40px;
  width: 100%;
  z-index: 0;
  box-shadow: 0 0 20px rgba(0,0,0,0.1)
}

.watermark-image {
  position: absolute;
  background-image: url('/public/watermark.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-position: center right;
  opacity: 0.15;
  left: 50px;
  top: 0px;
  right: 0px;
  height: 600px;
  z-index: -1;
}

.content-wrapper {
  position: relative;
  padding: 30px;
  padding-top: 10px;
  z-index: 1;
}

.heading,
h1, h2, h3, h4, h5 {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
}

.jumbo {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 3.5rem;
  line-height: 3rem;
}

p {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

footer {
  margin-top: 40px;
  color: #ccc;
  font-size: 0.8rem;
}

.details {
  position: absolute;
  top: 0px;
  left: 0px;
}

.no-link-style {
  color: #111;
  text-decoration: none;
}

@media only screen and (min-width: 800px) {
  .content-area {
    width: 80%;
  }
}

@media only screen and (min-width: 1000px) {
  .content-area {
    width: 900px;
  }

  .jumbo {
    font-size: 5.5rem;
    line-height: 5.5rem;
  }

  .content-wrapper {
    padding: 40px;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .content-area {
    width: 1000px;
  }
}
