

.line {
  margin-left: -20px;
  margin-right: -20px;
  height: 6px;
  background: rgba(0,0,0,0.2);
  border-radius: 1px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.graphic-container {
  position: absolute;
  width: 100px;
  right: 0;
  bottom: 0;
  overflow: hidden;
  border-radius: 16px;
}

.fancy-gradient {
  background: linear-gradient(90deg , #636363, #717171, #ABABAB);
}

.fancy-circle {
  width: 150px; /* Change this to adjust the size */
  height: 150px; /* Change this to adjust the size */
  border-radius: 50%; /* Makes the div a circle */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

/* letter */
/* https://tobiasahlin.com/moving-letters/#7 */

.ml7 {
  position: relative;
/*  font-weight: 900;
  font-size: 3.7em;*/
}
.ml7 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
}
.ml7 .word {
  transform-origin: 0 0%;
  display: inline-block;
  line-height: 1em;
  margin-right: 1rem;
  opacity: 0;
}

/* bento box */

.bento-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.bento-box .action-item {
  position: relative;
  background: #dce2e9;
  min-height: 40px;
  stroke: 2px solid #BFC3C9;
  border-radius: 16px;
  box-shadow: inset 0 2px 40px rgba(0,0,0,0.1);
  padding: 10px;
  cursor: pointer;
  filter: saturate(0.1);
  transition: box-shadow 200ms ease-in-out,
            filter 100ms ease-in-out,
            transform 50ms ease-in-out;

  font-size: 1.8rem;
  line-height: 1.8rem;
  color: #111;
  text-decoration: underline;
  min-height: 60px;

  white-space: break-spaces;
  overflow: hidden;

  text-shadow: 0 1px 0 rgba(255,255,255,0.5);
}

.bento-box .action-item img {
  z-index: -1;
}

.bento-box .action-item.focus,
.bento-box .action-item:hover {
  filter: saturate(1.1);
}

.bento-box .action-item:hover {
  box-shadow: inset 0 4px 40px rgba(0,0,0,0.25);
  transform: scale(1.01);
}


.bento-box .action-item img {
  transition: transform 100ms ease-in-out;
}

.bento-box .action-item:hover img {
  transform: translate(5px, 0) scale(1.05) rotateZ(2deg);
}

.bento-box .action-item:active {
  transform: scale(1);
}

.bento-box .row {
  display: flex;
  gap: 10px;
}

.bento-box .row .action-item {
  padding: 6px;
  font-size: 1.1rem;
  line-height: 1.1rem;
  flex: 1;
}

.bento-box .row div {
  flex: 1;
}

.bento-box .div1 {
  grid-area: 1 / 1 / 3 / 2;
}

.bento-box .div2 {
  grid-area: 1 / 2 / 3 / 3;
}

.bento-box .div3 {
  grid-area: 1 / 3 / 2 / 5;
}

.bento-box .div4 {
  grid-area: 2 / 3 / 3 / 5;
}

.bento-box .div5 {
  grid-area: 3 / 3 / 4 / 5;
}

.bento-box .div6 {
  grid-area: 3 / 1 / 5 / 3;
}

.bento-box .div7 {
  grid-area: 4 / 3 / 5 / 5;
}

/* responsive */

@media only screen and (max-width: 800px) {
  .bento-box,
  .bento-box .row {
    display: flex;
    flex-direction: column;
  }

  .bento-box .action-item,
  .bento-box .row .action-item {
    font-size: 1.4rem;
    padding: 10px;
  }
}