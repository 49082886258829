
/* Define the rotation animation */
@keyframes slowRotate {
  from {
    transform: rotate(20deg) scale(1.5);
  }
  to {
    transform: rotate(380deg) scale(1.5); /* Rotates 360 + 20 degrees */
  }
}

.rotating-mesh {
  position: absolute;
  top: -40px;
  left: 360px;
  opacity: 0.5;
  animation: slowRotate 100s linear infinite; /* Adjust the duration for speed */
}

@media only screen and (max-width: 800px) {
  .pulsing-mesh,
  .rotating-mesh {
    display: none;
  }
}

/* Define the scaling animation */
@keyframes pulse {
  0% {
    transform: rotate(30deg) scale(0.6);
  }
  50% {
    transform: rotate(30deg) scale(0.9); /* Enlarges to 1.2 times the original size */
  }
  100% {
    transform: rotate(30deg) scale(0.6); /* Returns to the original scale */
  }
}

.pulsing-mesh {
  position: absolute;
  top: 40px;
  left: -40px;
  animation: pulse 10s ease-in-out infinite; /* Adjust the duration for speed */
}

.title-link {
  opacity: 0.9;
  color: #303030;
}

.title-link:hover {
  opacity: 1;
  text-decoration: underline;
}

.hamburger-menu {
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
}

.hamburger-menu:hover {
  opacity: 1;
}

.main-links {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 90px;
  z-index: 10;
  padding: 10px;
  padding-bottom: 20px;
  background: #f2f2f2;
  border-radius: 4px;
  margin-bottom: 10px;
  pointer-events: none;
}

.main-links h3 {
  margin-top: 2px;
}

.main-links.show {
  pointer-events: all;
}

.main-links .inner {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 800px) {
  .main-links .inner {
    flex-direction: column;
  }

  .title-link h1 {
    padding-top: 8px;
    font-size: 1.5rem;
  }
}

.main-links .column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main-links .column a {
  text-decoration: none;
  color: #111;
  font-size: 1.1rem;
  line-height: 2rem;;
}

.main-links .column a:hover {
  text-decoration: underline;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid rgba(0,0,0,0.1);
}

ul.unstyled {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
}

/* */

.anim-in {
  opacity: 0;
  transform: translate(0, -10px);
  transition: opacity 500ms ease-in-out,
              transform 500ms ease-in-out;
}

.anim-in.loaded {
  transform: translate(0, 0px);
  opacity: 1;
}

.anim-in-fancy {
  opacity: 0;
  transform-origin: top left;
  transform: translate(0, -10px) scale(0.9) rotateX(10deg) rotateY(10deg);
  transition: opacity 200ms ease-in-out,
              transform 300ms ease-in-out;
}


.anim-in-fancy.show {
  transform: translate(0, 0px) scale(1) rotateX(0deg) rotateY(0deg);
  opacity: 1;
}

/* top menu etc. */


.subtitle {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  padding: 20px;
}

.menu-items,
.subtitle {
  background: #eee;
  border-radius: 4px 4px 0 0;
}

.menu-items {
  padding: 10px;
  padding-left: 20px;
}

.subtitle h3 {
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin: 0;
  color: #969696;
}

.subtitle img {
  margin-top: -5px;
}

.figure-text {
  padding-top: 5px;
  color: #aaa;
  word-break: break-all;
}