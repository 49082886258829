
/* https://cssgrid-generator.netlify.app */
.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  }
  .div1 { grid-area: 1 / 1 / 2 / 2; }
  .div2 { grid-area: 1 / 2 / 2 / 3; }
  .div3 { grid-area: 2 / 1 / 3 / 2; }
  .div4 { grid-area: 2 / 2 / 3 / 3; }
  .div5 { grid-area: 3 / 1 / 4 / 2; }
  .div6 { grid-area: 3 / 2 / 4 / 3; }

/* https://jsfiddle.net/juanjus98/b5vAQ/ */
.flip {
  -webkit-perspective: 800;
  -ms-perspective: 800;
  -moz-perspective: 800;
  -o-perspective: 800;
  width: calc(100% - 20px);
  height: 300px;
  position: relative;
  margin: 10px;
}
.flip .card.flipped {
  transform:rotatey(-180deg);
  -ms-transform:rotatey(-180deg); /* IE 9 */
  -moz-transform:rotatey(-180deg); /* Firefox */
  -webkit-transform:rotatey(-180deg); /* Safari and Chrome */
  -o-transform:rotatey(-180deg); /* Opera */
}
.flip .card {
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  -webkit-transition: 0.5s;
  -moz-transform-style: preserve-3d;
  -moz-transition: 0.5s;
  -ms-transform-style: preserve-3d;
  -ms-transition: 0.5s;
  -o-transform-style: preserve-3d;
  -o-transition: 0.5s;
  transform-style: preserve-3d;
  transition: 0.5s;
}
.flip .card .face {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  font-size: 3em;

  backface-visibility: hidden;  /* W3C */
  -webkit-backface-visibility: hidden; /* Safari & Chrome */
  -moz-backface-visibility: hidden; /* Firefox */
  -ms-backface-visibility: hidden; /* Internet Explorer */
  -o-backface-visibility: hidden; /* Opera */

}
.flip .card .front {
  transition: transform 200ms ease-in-out,
            text-shadow 200ms ease-in-out;
  position: absolute;
  z-index: 1;
  background: black;
  color: white;
  cursor: pointer;

  background-size: cover;
  background-position: center;

  /* centre align text */
  display: flex;
  align-items: center;
  justify-content: center;

  text-shadow: 0 2px 4px rgba(0,0,0,0.25);
  box-shadow: inset 0 2px 20px rgba(0,0,0,0.1);
}


.flip .card .front:hover {
  transform: scale(1.01);
  text-shadow: 0 4px 10px rgba(0,0,0,0.25);
}

/* custom scrollbars */
.flip .card ::-webkit-scrollbar {
    width: 10px;
    border-radius: 4px;
  }

/* Track */
.flip .card ::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 4px;
}

/* Handle */
.flip .card ::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 4px;
}

/* Handle on hover */
.flip .card ::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.flip .card .back {
  text-align: left;
  background: rgb(228, 228, 228);
  box-shadow: inset 0 2px 20px rgba(0,0,0,0.1);
  color: black;
  cursor: pointer;
  overflow-y: scroll;

  font-size: 0.8rem;
  line-height: 1.3rem;

  transform:rotatey(-180deg);
  -ms-transform:rotatey(-180deg); /* IE 9 */
  -moz-transform:rotatey(-180deg); /* Firefox */
  -webkit-transform:rotatey(-180deg); /* Safari and Chrome */
  -o-transform:rotatey(-180deg); /* Opera */

}

.flip .card .back p,
.flip .card .back h2 {
  padding: 10px;
}

@media only screen and (max-width: 800px) {

  .title-table {
    display: none;
  }

  .parent {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    .div1 { grid-area: 1 / 1 / 2 / 2; }
    .div2 { grid-area: 2 / 1 / 3 / 2; }
    .div3 { grid-area: 3 / 1 / 4 / 2; }
    .div4 { grid-area: 4 / 1 / 5 / 2; }
    .div5 { grid-area: 5 / 1 / 6 / 2; }
    .div6 { grid-area: 6 / 1 / 7 / 2; }

  .flip .card .face {
    font-size: 2rem;
  }

  .flip .card .back p,
  .flip .card .back h2 {
    padding: 8px;
  }
  .flip .card .back {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}