/* radio and button almost entirely from ChatGPT */

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.result-info {
  font-size: 1rem;
}

label {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #e7e7e7;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

label:hover input ~ .checkmark {
  background-color: #ccc;
}

label input:checked ~ .checkmark {
  background-color: #4c68af;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

label input:checked ~ .checkmark:after {
  display: block;
}

label .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* button */

button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #4c68af; /* Primary button color */
  border: none;
  border-radius: 25px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #2727bd; /* Slightly darker on hover */
}

button:active {
  background-color: #4c68af; /* Darker when pressed */
  transform: scale(0.98); /* Slight "pressed" effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(76, 94, 175, 0.4); /* Green focus ring */
}