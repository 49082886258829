
.ball-holder {
  position: absolute;
  opacity: 0;
  top: -100px;
  right: -100px;
  z-index: 1;
  
  transition: opacity 500ms ease-in-out;
}

.ball-holder.loaded {
  opacity: 0.98;
}

.ball-holder {
  display: none;
}

@media only screen and (min-width: 800px) {
  .content-area {
    width: 80%;
  }
  .ball-holder {
    display: block;
  }
}